var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5",attrs:{"tile":"","outlined":""}},[(!_vm.hideTitle)?_c('v-toolbar',{attrs:{"flat":"","color":"bg_section"}},[_c('v-toolbar-title',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_vm._t("actions"),(_vm.exportable)?_c('Dropdown',{attrs:{"actions":_vm.exportActions,"classes":{
        'ml-3': !!_vm.$slots['actions'],
      },"icon":"mdi-file-download-outline","label":"Export Dati"}}):_vm._e()],2):_vm._e(),(!_vm.hideTitle)?_c('v-divider'):_vm._e(),_c('v-data-table',{ref:"table",attrs:{"headers":_vm.computedHeaders,"items-per-page":_vm.itemsPerPage,"items":_vm.computedItems,"loading":_vm.computedLoading,"multi-sort":true,"options":_vm.options,"server-items-length":_vm.computedTotalRows,"show-select":_vm.showSelect,"item-key":_vm.itemKey,"footer-props":{ itemsPerPageOptions: [10, 25, 50, -1] },"hide-default-footer":_vm.hideDefaultFooter},on:{"update:options":function($event){_vm.options=$event},"input":_vm.onInput},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(){return [_c('v-tooltip',{key:h.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(h.hint || h.text))])])]},proxy:true}}),{key:"no-data",fn:function(){return [_c('v-icon',{staticClass:"text--secondary ma-4",attrs:{"x-large":""}},[_vm._v("mdi-table-search")]),_c('p',{staticClass:"subtitle-2"},[_c('strong',{staticClass:"mr-2"},[_vm._v("OPS!")]),_c('span',[_vm._v("Sembra non esserci nessun elemento disponibile ...")])])]},proxy:true},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.created_at,'DD/MM/YYYY HH:mm', '-'))+" ")]}},{key:"item.updated_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.updated_at,'DD/MM/YYYY HH:mm', '-'))+" ")]}},{key:"item.logged_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.logged_at,'DD/MM/YYYY HH:mm', '-'))+" ")]}},{key:"item.expires_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.expires_at,'DD/MM/YYYY HH:mm', '-'))+" ")]}},{key:"item.date_appointment",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.date_appointment,'DD/MM/YYYY HH:mm', '-'))+" ")]}},{key:"item.date_appointment_end",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.date_appointment_end,'DD/MM/YYYY HH:mm', '-'))+" ")]}},{key:"item.managed_at",fn:function(ref){
      var item = ref.item;
return [(item.tobe_managed_by)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(item.sla_1)?_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"red","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-alert ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" La lavorazione non è stata presa in carico entro lo SLA previsto: "+_vm._s(_vm._f("momentOr")(item.tobe_managed_by,'DD/MM/YYYY HH:mm', '-'))+" ")])]):_vm._e(),_c('span',{class:{ 'font-weight-bold': item.sla_1 }},[_vm._v(" "+_vm._s(_vm._f("momentOr")(item.managed_at,'DD/MM/YYYY HH:mm', '-'))+" ")])]}},{key:"item.closed_at",fn:function(ref){
      var item = ref.item;
return [(item.tobe_closed_by)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(item.sla_2)?_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","color":"red","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-alert ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" La lavorazione non è stata chiusa entro lo SLA previsto: "+_vm._s(_vm._f("momentOr")(item.tobe_closed_by,'DD/MM/YYYY HH:mm', '-'))+" ")])]):_vm._e(),_c('span',{class:{ 'font-weight-bold': item.sla_2 }},[_vm._v(" "+_vm._s(_vm._f("momentOr")(item.closed_at,'DD/MM/YYYY HH:mm', '-'))+" ")])]}},{key:"item.enabled",fn:function(ref){
      var item = ref.item;
return [(item.enabled)?_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v("Attivo")]):_c('v-chip',[_vm._v("Non attivo")])]}},{key:"item.active",fn:function(ref){
      var item = ref.item;
return [(item.active)?_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v("Attivo")]):_c('v-chip',[_vm._v("Non attivo")])]}},(_vm.willDisplayActions)?{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_vm._l((_vm.enabledActions),function(action){return [(_vm.shouldDisplayAction(action, item))?_c('Action',_vm._b({key:action.key,staticClass:"mr-2",attrs:{"target":item,"small":"","depressed":""}},'Action',action,false)):_vm._e()]}),_c('Dropdown',{attrs:{"actions":_vm.dropdownactions,"color":_vm.dropdowncolor,"target":item,"icon":"mdi-cog"}})]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }