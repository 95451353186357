<template>
  <v-menu v-if="hasActionsToDisplay" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <Action
        :button="button"
        :classes="classes"
        :color="color"
        :dense="dense"
        :depressed="depressed"
        :disabled="disabled"
        :icon="icon"
        :label="label"
        :outlined="outlined"
        :small="small"
        :text="text"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-list :dense="dense">
      <template v-for="(action, index) in enabledActions">
        <ListItemAction
          :key="`item_${index}`"
          :target="target"
          v-bind="action"
        />
        <v-divider
          v-if="index + 1 < enabledActions.length"
          :key="`divider_${index}`"
        ></v-divider>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { finalize } from '@utils/helper-functions'
import ListItemAction from '@components/structure/actions/list-item-action.vue'
import Action from '@components/structure/action.vue'

export default {
  name: 'Dropdown',
  components: {
    Action,
    ListItemAction,
  },
  props: {
    actions: {
      default: () => {},
      type: Array,
    },
    button: {
      type: Boolean,
    },
    classes: {
      default: () => [],
      type: [String, Array, Object],
    },
    color: {
      type: String,
    },
    dense: {
      type: Boolean,
    },
    depressed: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: [Boolean, Function],
    },
    icon: {
      type: String,
    },
    label: {
      default: 'Menu',
      type: String,
    },
    outlined: {
      type: Boolean,
    },
    small: {
      default: false,
      type: Boolean,
    },
    target: {
      type: Object,
    },
    text: {
      default: null,
      type: String,
    },
  },
  computed: {
    enabledActions() {
      // Understands if the actions will be hidden
      // either unconditionally because of "hide"
      // or conditionally because of the target
      return this.actions.filter((action) => {
        const willBeHidden = finalize(action, 'hide')
        // If teher's no condition it will be shown
        const willBeHiddenOnCondition = !(
          finalize(action, 'onItemCondition', this.target) ?? true
        )
        return !(willBeHidden || willBeHiddenOnCondition)
      })
    },
    hasActionsToDisplay() {
      return !!this.enabledActions.length
    },
  },
}
</script>
