<template>
  <v-btn
    :class="computedClasses"
    :color="computedColor"
    :dark="dark"
    :disabled="computedDisabled"
    :icon="shouldDisplayAsIcon"
    :outlined="outlined"
    :small="small"
    :to="computedTo"
    depressed
    tile
    @click="onClick"
    v-on="$listeners"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-if="!!icon"
          :class="{ [`mr-2`]: button && computedText }"
          :small="small"
          v-bind="attrs"
          v-on="on"
          >{{ computedIcon }}</v-icon
        >
        <span v-if="!shouldDisplayAsIcon">
          {{ computedText }}
        </span>
      </template>
      <span>
        {{ computedText }}
      </span>
    </v-tooltip></v-btn
  >
</template>

<script>
import { finalize } from '@utils/helper-functions'

export default {
  name: 'Action',
  props: {
    button: {
      default: false,
      type: Boolean,
    },
    classes: {
      default: () => [],
      type: [String, Array, Object],
    },
    color: {
      type: String,
    },
    dark: {
      type: Boolean,
    },
    depressed: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: [Boolean, Function],
    },
    getColor: {
      type: Function,
    },
    getIcon: {
      type: Function,
    },
    getLabel: {
      type: Function,
    },
    handler: {
      default: () => {},
      type: Function,
    },
    hide: {
      default: false,
      type: [Boolean, Function],
    },
    icon: {
      type: String,
    },
    label: {
      default: null,
      type: String,
    },
    onItemCondition: {
      default: () => true,
      type: [Boolean, Function],
    },
    outlined: {
      type: Boolean,
    },
    small: {
      default: false,
      type: Boolean,
    },
    target: {
      type: Object,
    },
    text: {
      default: null,
      type: String,
    },
    to: {
      type: [String, Object, Function],
    },
  },
  computed: {
    computedColor() {
      return (
        finalize(this, 'color', this.target) ||
        finalize(this, 'getColor', this.target)
      )
    },
    computedClasses() {
      return finalize(this, 'classes', this.target)
    },
    computedDisabled() {
      return finalize(this, 'disabled', this.target)
    },
    computedIcon() {
      return (
        finalize(this, 'icon', this.target) ||
        finalize(this, 'getIcon', this.target)
      )
    },
    computedText() {
      return (
        finalize(this, 'text', this.target) ||
        finalize(this, 'label', this.target) ||
        finalize(this, 'getLabel', this.target)
      )
    },
    computedTo() {
      return finalize(this, 'to', this.target)
    },
    /**
     * Understands if it should display as an icon rather than a button
     * If an icon is not provided it will default as button.
     * This has nothing to do with icons INSIDE the button
     */
    shouldDisplayAsIcon() {
      return !this.button && !!this.icon
    },
  },
  methods: {
    onClick() {
      if (this.to) return undefined
      return this.handler(this.target)
    },
  },
}
</script>
