<template>
  <v-card class="mt-5" tile outlined>
    <v-toolbar v-if="!hideTitle" flat color="bg_section">
      <v-toolbar-title class="font-weight-medium">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <slot name="actions"> </slot>
      <Dropdown
        v-if="exportable"
        :actions="exportActions"
        :classes="{
          'ml-3': !!$slots['actions'],
        }"
        icon="mdi-file-download-outline"
        label="Export Dati"
      />
    </v-toolbar>
    <v-divider v-if="!hideTitle" />

    <v-data-table
      :ref="`table`"
      v-model="selected"
      :headers="computedHeaders"
      :items-per-page="itemsPerPage"
      :items="computedItems"
      :loading="computedLoading"
      :multi-sort="true"
      :options.sync="options"
      :server-items-length="computedTotalRows"
      :show-select="showSelect"
      :item-key="itemKey"
      :footer-props="{ itemsPerPageOptions: [10, 25, 50, -1] }"
      :hide-default-footer="hideDefaultFooter"
      @input="onInput"
    >
      <template v-for="h in headers" v-slot:[`header.${h.value}`]>
        <v-tooltip :key="h.value" bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ h.text }}</span>
          </template>
          <span>{{ h.hint || h.text }}</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-icon x-large class="text--secondary ma-4">mdi-table-search</v-icon>
        <p class="subtitle-2">
          <strong class="mr-2">OPS!</strong>
          <span>Sembra non esserci nessun elemento disponibile ...</span>
        </p>
      </template>

      <!--  mi becco eventuali slot dal parent  -->
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ item.updated_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
      </template>
      <template v-slot:item.logged_at="{ item }">
        {{ item.logged_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
      </template>
      <template v-slot:item.expires_at="{ item }">
        {{ item.expires_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
      </template>
      <template v-slot:item.date_appointment="{ item }">
        {{ item.date_appointment | momentOr('DD/MM/YYYY HH:mm', '-') }}
      </template>
      <template v-slot:item.date_appointment_end="{ item }">
        {{ item.date_appointment_end | momentOr('DD/MM/YYYY HH:mm', '-') }}
      </template>

      <template v-slot:item.managed_at="{ item }">
        <v-tooltip v-if="item.tobe_managed_by" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="item.sla_1"
              left
              color="red"
              small
              v-bind="attrs"
              v-on="on"
            >
              mdi-clock-alert
            </v-icon>
          </template>
          <span>
            La lavorazione non è stata presa in carico entro lo SLA previsto:
            {{ item.tobe_managed_by | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </span>
        </v-tooltip>

        <span :class="{ 'font-weight-bold': item.sla_1 }">
          {{ item.managed_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
        </span>
      </template>
      <template v-slot:item.closed_at="{ item }">
        <v-tooltip v-if="item.tobe_closed_by" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="item.sla_2"
              left
              color="red"
              small
              v-bind="attrs"
              v-on="on"
            >
              mdi-clock-alert
            </v-icon>
          </template>
          <span>
            La lavorazione non è stata chiusa entro lo SLA previsto:
            {{ item.tobe_closed_by | momentOr('DD/MM/YYYY HH:mm', '-') }}
          </span>
        </v-tooltip>
        <span :class="{ 'font-weight-bold': item.sla_2 }">
          {{ item.closed_at | momentOr('DD/MM/YYYY HH:mm', '-') }}
        </span>
      </template>

      <template v-slot:item.enabled="{ item }">
        <v-chip v-if="item.enabled" color="green" dark>Attivo</v-chip>
        <v-chip v-else>Non attivo</v-chip>
      </template>

      <template v-slot:item.active="{ item }">
        <v-chip v-if="item.active" color="green" dark>Attivo</v-chip>
        <v-chip v-else>Non attivo</v-chip>
      </template>

      <template v-if="willDisplayActions" v-slot:item.actions="{ item }">
        <template v-for="action in enabledActions">
          <Action
            v-if="shouldDisplayAction(action, item)"
            v-bind="action"
            :key="action.key"
            :target="item"
            small
            depressed
            class="mr-2"
          />
        </template>
        <Dropdown
          :actions="dropdownactions"
          :color="dropdowncolor"
          :target="item"
          icon="mdi-cog"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Action from '@components/structure/action.vue'
import Dropdown from '@components/structure/dropdown.vue'
import { finalize } from '@utils/helper-functions'

export default {
  name: 'BaseTable',
  components: {
    Action,
    Dropdown,
  },
  props: {
    actions: {
      type: Array,
      required: false,
      default: () => [],
    },
    dropdownactions: {
      type: Array,
      default: () => [],
    },
    dropdowncolor: {
      type: String,
      default: 'primary',
    },
    elevation: {
      type: Number,
      default: 6,
    },
    exportable: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default() {
        return []
      },
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: null,
    },
    sortby: {
      type: [String, Array],
      default: 'created_at',
    },
    sortDesc: {
      type: [String, Boolean],
      default: 'true',
    },
    title: {
      type: String,
      default: 'Dati',
    },
    totalRows: {
      type: Number,
      default: 0,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: null,
    },
    value: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      selected: this.value || [],
      options: {
        page: 1,
        itemsPerPage: this.itemsPerPage || 10,
        multiSort: true,
        mustSort: false,
        sortBy: typeof this.sortby === 'string' ? [this.sortby] : this.sortby,
        sortDesc: [this.sortDesc],
      },
    }
  },
  computed: {
    computedItems() {
      let target = this.items
      if (this.module) target = this.$store.state[this.module].pagination.rows
      return target
    },
    computedTotalRows() {
      let target = this.totalRows
      if (this.module)
        target = this.$store.state[this.module].pagination.totalRows
      return target
    },
    computedLoading() {
      let target = this.loading
      if (this.module) target = this.$store.state[this.module].loading
      return target
    },
    computedHeaders() {
      return [
        ...this.headers,
        ...(this.willDisplayActions
          ? [
              {
                text: 'Azioni',
                value: 'actions',
                sortable: false,
              },
            ]
          : []),
      ]
    },

    enabledActions() {
      // Understands if the actions will be hidden
      // either unconditionally because of "hide"
      // or conditionally because of the item
      return this.actions.filter((action) => {
        const willBeHidden = finalize(action, 'hide')
        if (willBeHidden) return false
        // If any action will be displayed this is false
        const willBeHiddenForAllItems = !this.computedItems.some((item) =>
          this.shouldDisplayAction(action, item)
        )
        return !willBeHidden && !willBeHiddenForAllItems
      })
    },
    willDisplayActions() {
      return !!this.enabledActions.length
    },

    exportActions() {
      return [
        { format: 'XLSX', icon: 'mdi-file-excel' },
        { format: 'CSV', icon: 'mdi-file-delimited' },
      ].map((action) => ({
        text: `Download in ${action.format}`,
        icon: action.icon,
        handler: () => {
          if (this.module) {
            this.$store.dispatch(`${this.module}/download`, {
              format: action.format.toLowerCase(),
            })
          }
          this.$emit('export', action.format.toLowerCase())
        },
      }))
    },
  },
  watch: {
    options: {
      handler(ctx) {
        if (this.module) {
          this.searchWithModule(ctx)
        }
        this.$emit('refresh', ctx)
      },
      deep: true,
    },
  },
  beforeDestroy() {
    if (this.module) {
      this.$store.commit(`${this.module}/RESET_PAGINATED_ITEMS`)
    }
  },
  methods: {
    refresh() {
      /* Changing the options will trigger the watcher that will automatically search */
      this.options = {
        page: 1,
        itemsPerPage: 10,
        multiSort: true,
        mustSort: false,
        sortBy: [this.sortby],
        sortDesc: [this.sortDesc],
      }
    },
    searchWithModule(ctx) {
      return this.$store.dispatch(`${this.module}/getItems`, ctx)
    },
    shouldDisplayAction(action, item) {
      let answer = true
      if (action.onItemCondition) {
        answer = action.onItemCondition(item)
      }
      return answer
    },
    exp(format) {
      if (this.module) {
        this.$store.dispatch(`${this.module}/download`, { format })
      }
      this.$emit('export', format)
    },

    onInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>
