<template>
  <v-list-item
    :class="computedClasses"
    :disabled="computedDisabled"
    :to="computedTo"
    @click="onClick"
  >
    <v-icon v-if="icon" v-bind="$attrs" left>
      {{ computedIcon }}
    </v-icon>
    <v-list-item-title>{{ computedText }}</v-list-item-title>
    <v-spacer></v-spacer>
  </v-list-item>
</template>

<script>
import { finalize } from '@utils/helper-functions'
import { isUndefined } from 'axios/lib/utils'

export default {
  name: 'ListItemAction',
  props: {
    classes: {
      default: () => [],
      type: [String, Array, Object],
    },
    disabled: {
      default: false,
      type: [Boolean, Function],
    },
    event: {
      type: String,
    },
    getIcon: {
      type: Function,
    },
    getLabel: {
      type: Function,
    },
    handler: {
      default: () => {},
      type: Function,
    },
    icon: {
      type: String,
    },
    label: {
      default: null,
      type: String,
    },
    onItemCondition: {
      default: () => true,
      type: [Boolean, Function],
    },
    target: {
      type: Object,
    },
    text: {
      default: null,
      type: String,
    },
    to: {
      type: [String, Object, Function],
    },
  },
  computed: {
    computedClasses() {
      return finalize(this, 'classes', this.target)
    },
    computedDisabled() {
      return finalize(this, 'disabled', this.target)
    },
    computedIcon() {
      return (
        finalize(this, 'icon', this.target) ||
        finalize(this, 'getIcon', this.target)
      )
    },
    computedText() {
      return (
        finalize(this, 'text', this.target) ||
        finalize(this, 'label', this.target) ||
        finalize(this, 'getLabel', this.target)
      )
    },
    computedTo() {
      return finalize(this, 'to', this.target)
    },
  },
  methods: {
    onClick() {
      if (this.to) return isUndefined
      return this.handler(this.target)
    },
  },
}
</script>
